<template>
  <div>
    <div
      v-if="loading"
      class="spinner-block"
    >
      <b-spinner />
    </div>
    <div v-else>
      <!-- Order info Cards -->
      <order-info-cards
        :order-info="orderDetails"
      />
      <!-- Searchbar and select products -->
      <div class="kp-sticky-search-wrapper">
        <products-search-section
          :shipment-list="shipmentList"
          @on-search="updateSearch"
          @on-select-all="selectAllProducts"
          @on-deselect-all="deselectAllProducts"
          @on-select-ready-to-ship-products="selectReadyToShipProducts"
          @load-order="loadOrderDetails"
        />
      </div>
      <!-- Order products -->
      <div>
        <b-tabs
          class="kp-tabs"
          nav-class="kp-nav-tabs"
          content-class="mt-3"
        >
          <b-tab
            title="Unassigned styles"
            active
          >
            <products
              v-if="getOrderProductsPreShipment.length"
              :products="getOrderProductsPreShipment"
              :shipment-list="shipmentList"
              :enable-pagination="true"
              @on-style-change-cancelled="handleStyleStatusChangeCancelled"
              @on-style-assign-shipment="handleStyleShipmentAssignment"
              @total-value="updateOrderTotal"
            />
          </b-tab>
          <b-tab title="Assigned to shipment">
            <products
              v-if="getOrderProductsInShipment.length"
              :products="getOrderProductsInShipment"
              :shipment-list="shipmentList"
              :enable-pagination="true"
              @on-style-change-cancelled="handleStyleStatusChangeCancelled"
              @on-style-assign-shipment="handleStyleShipmentAssignment"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { apiToastError } from '@/@core/utils/toast'
import { isProductInShipment } from '@/common-utils'
import { FETCH_ORDER_BY_ID } from '@/store/modules/order.module'
import {
  SELECT_READY_TO_SHIP_PRODUCTS,
  SET_ALL_PRODUCT_DESELECTED, SET_ALL_PRODUCT_SELECTED,
  SET_CURRENT_ORDER_PRODUCTS,
  SET_SINGLE_SELECTED_PRODUCT,
} from '@/store/modules/product.module'
import {
  SET_COMPONENT, SET_COMPONENT_CONTENT, SET_FORM_ACTIONS, SET_SUB_TITLE, SET_TITLE, TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { productUtil } from '@kingpin-global/kingpin-utils-frontend'
import { BSpinner } from 'bootstrap-vue'
import Products from '../product/Products.vue'
import ProductsSearchSection from '../product/ProductsSearchSection.vue'
import SelectedStylesContent from '../product/SelectedStylesContent.vue'
import { SIDE_PANEL_TYPES } from '../product/product-utils'
import OrderInfoCards from './OrderInfoCards.vue'

const { getProductAndOrderTotals } = productUtil

export default {
  name: 'OrderProductsTab',
  components: {
    OrderInfoCards,
    Products,
    BSpinner,
    ProductsSearchSection,
  },
  data() {
    return {
      orderId: this.$route.params.orderId,
      orderDetails: null,
      products: [],
      loading: false,
      searchingText: '',
      searchResult: [],
      shipmentList: [],
      totalQuantities: 0,
      totalPrice: 0,
      shipmentTotalQuantity: 0,
      shipmentTotalPrice: 0,
      shipmentTotalSKU: 0,
    }
  },
  computed: {
    getSearchResultForProductsInShipment() {
      return this.searchResult.length
        ? this.searchResult.filter(product => isProductInShipment(product.status))
        : []
    },
    getSearchResultForProductsPreShipment() {
      return this.searchResult.length
        ? this.searchResult.filter(product => !isProductInShipment(product.status))
        : []
    },
    getOrderProductsInShipment() {
      return this.getSearchResultForProductsInShipment
    },
    getOrderProductsPreShipment() {
      return this.getSearchResultForProductsPreShipment
    },
  },
  created() {
    this.loadOrderDetails()
    this.$root.$refs.products = this
  },
  methods: {
    loadOrderDetails() {
      this.loading = true
      this.$store
        .dispatch(FETCH_ORDER_BY_ID, this.orderId)
        .then(res => {
          this.orderDetails = res
          this.loading = false
          this.initSearchResult()
          this.$store.commit(SET_CURRENT_ORDER_PRODUCTS, this.orderDetails.products)
          // resetthe previously selected products
          this.deselectAllProducts()
          this.getShipmentList()
        })
        .catch(err => {
          apiToastError(err)
          this.loading = false
        })
    },
    getShipmentList() {
      this.$kpRequest({
        ...this.$kpEndpoint.shipment.getShipments,
        params: {
          orderId: this.orderId, sortBy: 'shipmentSeqId', asc: true, isMinimalData: true,
        },
      })
        .then(res => {
          this.shipmentList = res.data.data
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    updateSearch(searchingText) {
      this.searchingText = searchingText
      if (searchingText) {
        this.searchResult = this.orderDetails.products.filter(product => {
          const isStyleName = product.name.toLowerCase()
            .indexOf(searchingText.toLowerCase()) !== -1
          const isStyleCode = product.styleCode.toLowerCase()
            .indexOf(searchingText.toLowerCase()) !== -1
          return isStyleName || isStyleCode
        })
      } else {
        this.initSearchResult()
      }
    },
    initSearchResult() {
      this.searchResult = this.orderDetails.products
    },
    selectAllProducts() {
      this.$store.commit(SET_ALL_PRODUCT_SELECTED, this.orderDetails.products)
    },
    deselectAllProducts() {
      this.$store.commit(SET_ALL_PRODUCT_DESELECTED)
    },
    selectReadyToShipProducts() {
      this.$store.commit(SELECT_READY_TO_SHIP_PRODUCTS, this.orderDetails.products)
    },
    async handleStyleStatusChangeCancelled(productId) {
      this.$store.commit(SET_SINGLE_SELECTED_PRODUCT, productId)
      this.$store.commit(SET_TITLE, 'Canceling Styles')
      this.$store.commit(SET_SUB_TITLE, '')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { type: SIDE_PANEL_TYPES.CANCEL })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    async handleStyleShipmentAssignment({ shipmentId, shipmentSeqId }) {
      this.$store.commit(SET_TITLE, 'Assigning To Shipment')
      this.$store.commit(SET_SUB_TITLE, '')
      this.$store.commit(SET_COMPONENT, SelectedStylesContent)
      this.$store.commit(SET_COMPONENT_CONTENT, { shipmentObjId: shipmentId, shipmentSeqId, type: SIDE_PANEL_TYPES.ASSIGN })
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    emitActions(action) {
      switch (action) {
        // when click cancel
        case 'cancel': {
          this.toggleSidebar()
          break
        }
        // When API returns success response
        case 'success': {
          this.loadOrderDetails()
          this.toggleSidebar()
          break
        }
        default: {
          break
        }
      }
    },
    updateOrderTotal() {
      const role = this.$store.getters.role
      getProductAndOrderTotals(role, this.orderDetails)
    },
  },
}
</script>
